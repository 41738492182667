import { createPatient } from "./patient";
import { createClinicalReasons } from "./clinicalReasons";
import { createInsuranceInformation } from "./insuranceInforamation";
import { networkLayer } from "./networkLayer";
import { config } from "../config"
import {connectDevice, connectMultipleDevices, connectRPMDevice, createDeviceMeasurement} from "./device";
import {emptyValueFormatter, isHolter, isMCT, isRPM, isTwoWeekHolter} from "../utils/utils";
import { setValueToSessionStorage } from "./auth";

export type StudyType = 'mct' | 'holter';
export type Enrollment = 'clinic' | 'home';

export enum StudyStatusesEnum {
  not_enrolled = "not_enrolled",
  draft = 'draft',
  in_review = 'in_review',
  inprogress = 'inprogress',
  interpretating = 'interpretating',
  completed = 'completed'
}

export enum StudySeveritiesEnum {
  level_1 = 1,
  level_2 = 2,
  level_3 = 3,

}

export type StudyStatuses = keyof typeof StudyStatusesEnum;

export interface StudyInterface {
  enrollment: Enrollment;
  studyType: StudyType;
  idUnique: string;
  status: StudyStatuses;
}

export interface CalibrationObject {
  systolic: number;
  diastolic: number;
  heartRate: number;
  studyId: string;
}

const generatePatientFields = (values: any) => ({
  first_name: values.firstName,
  last_name: values.lastName,
  gender: values.gender,
  birthday: values.birthday,
  id_unique: values.id_unique
});

const generateClinicalReasonsFields = (values: any) => ({
  primary_indication: values.primaryIndication,
  reason_for_test: emptyValueFormatter(values.reasonForTest),
  medications: emptyValueFormatter(values.medications),
  has_pacemaker: values.hasPacemaker === 'yes',
  had_stroke: values.hadStroke  === 'yes',
});

const generateInsuranceInformationFields = (values: any) => ({
  billing_method: values.billingMethod,
  insurance_number: values.insuranceNumber,
});

const generateDraftStudyFields = (values: any) => ({
  patient: values.patient,
  clinicalReasons: values.clinicalReasons,
  insuranceInformation: values.insuranceInformation,
  serialNumber: values.serialNumber,
  enrollment: values.enrollment,
  study_type: values.study_type,
  date_start: values.date_start,
});

export const createStudy = async (values: any) => {
  if (!values) return;

  const patientFields = generatePatientFields(values);
  const clinicalReasonsFields = generateClinicalReasonsFields(values);
  const insuranceInformationFields = generateInsuranceInformationFields(values);

  const patient = await createPatient(patientFields, insuranceInformationFields, clinicalReasonsFields);

  if (isHolter(values.studyType)){
    return await createHolterStudy(values,patient);
  }

  if (isTwoWeekHolter(values.studyType)){
    return await createTwoWeekHolterStudy(values,patient);
  }

  if(isRPM(values.studyType)){
    return await createRPMStudy(values,patient);
  }
}

export const createHolterStudy = async(values: any,patient: any) => {
  const studyEndpoint = values.study_id ? `${config.dataUrls}/dashboard/study/publish/` : `${config.dataUrls}/dashboard/study/`;
  const study = await networkLayer('post', studyEndpoint, {
    enrollment: values.enrollment,
    study_type: values.studyType,
    date_start: values.startDate,
    id_unique: patient.data.unique_id,
    study_id: values.study_id,
    measurements_data: [
      {
        device_serial_number: values.devices[0].value,
        device_type: values.devices[0].deviceType,
        date_start: values.startDate,
      }
    ]
  });


  if (study.message === '200_1' && values.devices[0].deviceType !== "dms") {
    window.location.href = '/';
  }

  return study;
}

export const createTwoWeekHolterStudy = async(values: any,patient: any) => {
  const studyEndpoint = values.study_id ? `${config.dataUrls}/dashboard/study/publish/` : `${config.dataUrls}/dashboard/study/`;

  let measurements_data  = values.startDates.map((item: Date | null, index: number) => {
    return {
      date_start: item,
      device_serial_number: values.devices[index].value,
      device_type: values.devices[index].deviceType,
    };
  });
  const study = await networkLayer('post', studyEndpoint, {
    enrollment: values.enrollment,
    study_type: values.studyType,
    date_start: values.startDate,
    id_unique: patient.data.unique_id,
    study_id: values.study_id,
    measurements_data : measurements_data

  });

  if (study.message === '200_1') {
    window.location.href = '/';
  }

  return study;
}

export const createRPMStudy = async(values: any,patient: any) => {
  const studyEndpoint = values.study_id ? `${config.dataUrls}/dashboard/study/publish/` : `${config.dataUrls}/dashboard/study/`;

  const study = await networkLayer('post', studyEndpoint, {
    enrollment: values.enrollment,
    study_type: values.studyType,
    date_start: values.startDate,
    id_unique: patient.data.unique_id,
    study_id: values.study_id
  });

  let reminders = [];
  for (const x of values.ecg_frequency) {
    reminders.push(x['value'].getHours().toString() + ":" + x['value'].getUTCMinutes().toString());
  }

  await connectRPMDevice({
    id_unique: patient.data.unique_id,
    serial_number: values.devices[0].value,
    study_id: values.study_id || study.data.id,
    ppg_frequency : values.ppg_frequency,
    ecg_frequency : reminders,
    wifi_login : values.wifi_login,
    wifi_password : values.wifi_password
  });

  if (isRPM(values.studyType)) {
    await setValueToSessionStorage('study', study.data);
  }

  return study;
}


  //     // await connectDevice({
//     //   id_unique: patient.data.unique_id,
//     //   serial_number: values.deviceSerialNumber
//     // });



  // if (isMCT(values.studyType)) {
  //   await connectMultipleDevices({
  //     id_unique: patient.data.unique_id,
  //     serial_numbers: values.deviceSerialNumber,
  //     study_id: values.study_id || study.data.id
  //   })
  //
  //   await Promise.all(
  //     values.deviceSerialNumber.map(async (serial_number: string) => {
  //       await createDeviceMeasurement({
  //         serial_number,
  //         study_id: values.study_id || study.data.id,
  //         date_start: values.startDate,
  //         id_unique: patient.data.unique_id,
  //         sharable_code: study.data.sharable_code
  //       })
  //     })
  // )
  // }

export const createDraftStudy = async (values: any) => {
  const patientFields = generatePatientFields(values);
  const clinicalReasonsFields = generateClinicalReasonsFields(values);
  const insuranceInformationFields = generateInsuranceInformationFields(values);

  const study = await networkLayer('post', `${config.dataUrls}/dashboard/study/`, {
    draft_data: generateDraftStudyFields({
      patient: patientFields,
      clinicalReasons: clinicalReasonsFields,
      insuranceInformation: insuranceInformationFields,
      serialNumber: values.devices[0].value,
      enrollment: values.enrollment,
      study_type: values.studyType,
      date_start: values.startDate,
    })
  });

  if (study.message === '200_1') {
    window.location.href = '/';
  }

  return study;
}

export const updateDraftStudy = async (values: any) => {
  const patientFields = generatePatientFields(values);
  const clinicalReasonsFields = generateClinicalReasonsFields(values);
  const insuranceInformationFields = generateInsuranceInformationFields(values);

  const study = await networkLayer('patch', `${config.dataUrls}/dashboard/study/update_draft/`, {
    study_id: values.study_id,
    draft_data: generateDraftStudyFields({
      patient: patientFields,
      clinicalReasons: clinicalReasonsFields,
      insuranceInformation: insuranceInformationFields,
      serialNumber: values.devices[0].value,
      enrollment: values.enrollment,
      study_type: values.studyType,
      date_start: values.startDate,
    })
  });

  if (study.message === '200_2') {
    window.location.href = '/';
  }

  return study;
}

export const deleleStudy = async (study_id : string) => {
  const deleletedStudy = await networkLayer('post', `${config.dataUrls}/dashboard/study/delete/`, { study_id } );

  if (deleletedStudy.message === '200_3') {
    window.location.href = '/';
  }

  return deleletedStudy;
}

export const getStudy = async (study_id : string) => {
  return await networkLayer('get',
      `${config.dataUrls}/dashboard/study`,
      null,
      { study_id });
}

export const finishStudy = async (study_id : string) => {
  const finishedStudy = await networkLayer('post', `${config.dataUrls}/dashboard/study/finish/`, { study_id } );

  if (finishedStudy.message === '200_2') {
    window.location.href = '/';
  }

  return finishStudy;
}


export const getStudiesList = async () => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/study/list/`);
}

export const getStudiesListDevices = async (study_id : string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/study/list_devices/`, null, { study_id } );
}

export const getStudiesListReports = async (study_id : string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/study/list_reports/`, null, { study_id } );
}

export const getStudiesMCTListDevices = async (study_id : string) => {
  return await networkLayer('get', `${config.deviceUrl}/dashboard/study/mct/list_devices/`, null, { study_id } );
}

export const createCalibrationObject = async (values: CalibrationObject) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/study/calibration_object/`, {
    systolic: values.systolic,
    diastolic: values.diastolic,
    heart_rate: values.heartRate,
    study_id: values.studyId,
    date: new Date().toISOString()
  });
}

export const createCalibrationObjectCheckCast = async (study_id: string) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/study/calibration_object/check_cast/`, { study_id });
}

export const getSaturationList = async (study_id : string, date: Date | string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/list_saturation/`, null, { study_id, date } );
}

export const getHeartRateList = async (study_id : string, date: Date | string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/list_heart_rates/`, null, { study_id, date } );
}

export const getPPGList = async (study_id : string, date: Date | string, page_number : number) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/list_ppg/`, null, { study_id, date, page_number } );
}

export const getPPGData = async (id : string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/ppg_data/${id}`, null);
}

export const getECGList = async (study_id : string, date: Date | string, page_number : number) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/list_ecg/`, null, { study_id, date, page_number } );
}

export const getECGData = async (id : string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/ecg_data/${id}`, null );
}

export const getBPList = async (study_id : string, date: Date | string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/list_bp/`, null, { study_id, date } );
}

export  const listStudiesAdmin = async(filter_values:any) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/itadmin/studies/`, null, filter_values);
}

export  const listStudiesClinicAdmin = async(id_department:string|undefined) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/itadmin/studies_department/`, null, { id_department } );
}

export const getStudyAdmin = async(id_study:string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/itadmin/study/${id_study}`, null);
}

export const updateStudyMeasurement = async(measurement_data:any) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/update_measurement/`, measurement_data, null)
}

export const uploadPDFReport = async(report_data:any) => {
  return await networkLayer('post', `${config.dataUrls}/measurement/exams/`, report_data, null)
}

export const reSubmitStudy = async(study_data:any) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/itadmin/studies/re-upload/`, study_data, null)
}

export const getUploadingCredentials = async() => {
  return await networkLayer('post', `${config.dataUrls}/measurement/holter/temporal_credentials/`, null, null)
}

export const getStudyLogsAdmin = async(id_study: string) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/itadmin/study/${id_study}/studylog/`, null);
}

export const signStudy = async(id_study: string) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/itadmin/study/${id_study}/sign/`, {});
}

export const declineStudy = async(id_study: string, message: string) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/itadmin/study/${id_study}/decline/`, { message });
}

export const updateStudy = async(data: any, id_study: string) => {
  return await networkLayer('post', `${config.dataUrls}/dashboard/itadmin/study/${id_study}/update/`, data);
}

export const getStudyFileURL = async(id_study: any) => {
  return await networkLayer('get', `${config.dataUrls}/dashboard/itadmin/study/${id_study}/data_file/`, null);
}