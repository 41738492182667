export const ENROLLMENT_JSON = {
    "firstName": "Artem",
    "middleName": "T",
    "lastName": "Test",
    "DOBMonth": 1,
    "DOBDay": 1,
    "DOBYear": 2010,
    "dateOfRecording": "7/9/2024",
    "hookupHR": 18,
    "hookupMIN": 55,
    "patientID": "ID_TEST",
    "sex": "M",
    "serialNumber": "07B70CA5"
}

export const CONFIGURATION_JSON = {
    "sample_rate": 256,
    "record_period": 72,
    "CH1": "enable",
    "CH2": "enable",
    "CH3": "disable",
    //"max_sample_rate": 1024,
    //"max_record_period": 480,
    // "graph_offset_ch1": 0, // ?
    // "graph_offset_ch2": 0, // ?
    // "graph_offset_ch3": 0, // ?
    // "CH1P": "IN3", // ?
    // "CH1N": "IN2", // ?
    // "CH2P": "IN3", // ?
    // "CH2N": "IN1", // ?
    // "CH3P": "IN4", // ?
    // "CH3N": "IN1", // ?
    // "WCT1": "IN5", // ?
    // "WCT2": "IN5", // ?
    // "WCT3": "IN5", // ?
    // "CH1_DESC": "L2", // Standard lead name?
    // "CH2_DESC": "L3", // Standard lead name?
    // "CH3_DESC": "V5", // Standard lead name?
    "serial_number": "07B70CA5"
}


const toDate = (input: Date | string): Date => {
    return input instanceof Date ? input : new Date(input);
};

export function createEnrollmentFile(firstName: string,
                                 lastName: string,
                                 birthday: Date | string,
                                 hookupTime: Date | string,
                                 idPatient: string,
                                 deviceSerialNumber : string,
                                 gender : string) {

    const birthdayDate = toDate(birthday);
    const hookupTimeDate = toDate(hookupTime);

    return {
        "firstName": firstName,
        "middleName": "-",
        "lastName": lastName,
        "DOBMonth": birthdayDate.getMonth(),
        "DOBDay": birthdayDate.getDay(),
        "DOBYear": birthdayDate.getFullYear(),
        "dateOfRecording": `${hookupTimeDate.getMonth() + 1}/${hookupTimeDate.getDate()}/${hookupTimeDate.getFullYear()}`,
        "hookupHR": hookupTimeDate.getHours(),
        "hookupMIN": hookupTimeDate.getMinutes(),
        "patientID": idPatient,
        "sex": gender,
        "serialNumber": deviceSerialNumber
    }
}

export function createConfigFile(sample_rate: number, record_period: number, channels: any, serial_number : string | null) {
    console.log(channels);
    return {
        "sample_rate": sample_rate,
        "record_period": record_period * 24,
        "CH1": channels.includes('1') ? "enable" : 'disable',
        "CH2": channels.includes('2') ? "enable" : 'disable',
        "CH3": channels.includes('3') ? "enable" : 'disable',
        "serial_number" : serial_number
    }
}

export function bufferToStringSN(dataView:DataView) {
    let string = '';
    for (let i = 2; i < dataView.byteLength - 1; i++) {
        string += String.fromCharCode(dataView.getUint8(i));
    }
    return string;
}




export function dateToBuffer(): Uint8Array {
    const buffer = new Uint8Array(10);
    const utcNow = new Date();

    buffer[0] = 192;
    buffer[1] = 2;
    buffer[2] = utcNow.getUTCSeconds();
    buffer[3] = utcNow.getUTCMinutes();
    buffer[4] = utcNow.getUTCHours();
    buffer[5] = (utcNow.getUTCDay() === 0) ? 7 : utcNow.getUTCDay(); // Replace with the appropriate value for num1
    buffer[6] = utcNow.getUTCDate();
    buffer[7] = utcNow.getUTCMonth() + 1; // getUTCMonth() returns 0-11, so add 1
    buffer[8] = utcNow.getUTCFullYear() - 2000; // Convert full year to 2-digit year
    buffer[9] = 192;

    console.log(buffer);
    return buffer;
}

export function parseDateFromBuffer(byteArray: DataView): Date {
    const year = byteArray.getUint8(byteArray.byteLength - 2) + 2000;
    const month = byteArray.getUint8(byteArray.byteLength - 3);
    const day = byteArray.getUint8(byteArray.byteLength - 4);
    const hours = byteArray.getUint8(byteArray.byteLength - 6);
    const minutes = byteArray.getUint8(byteArray.byteLength - 7);
    const seconds = byteArray.getUint8(byteArray.byteLength - 8);


    console.log(year,month,day, hours,minutes,seconds,byteArray);
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds)); // Date.UTC month is 0-based
}