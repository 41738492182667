// import { useState, useEffect } from "react";
// import { Box, InputLabel, FormHelperText } from '@mui/material';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { isValidDate, pickersMigrationHack } from '../utils/utils';
//
// type Value = Date | null;
//
// interface MDateTimePickerProps {
//   dateLabel?: string;
//   timeLabel?: string;
//   value?: Value;
//   onChange: (date: Value) => void;
//   disabled?: boolean;
//   error?: boolean;
//   dateErrorText?: string;
//   timeErrorText?: string;
// }
//
// export const MDateAndTimePicker = (props: MDateTimePickerProps) => {
//   const { dateLabel, timeLabel, value = null, onChange, disabled, error, dateErrorText, timeErrorText } = props;
//   console.log(props);
//
//   const extractDate = (dateTime: Value) => (dateTime ? new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()) : null);
//   const extractTime = (dateTime: Value) => (dateTime ? new Date(1970, 0, 1, dateTime.getHours(), dateTime.getMinutes()) : null);
//
//   const [date, setDate] = useState<Value>(extractDate(value));
//   const [time, setTime] = useState<Value>(extractTime(value));
//
//   // const [date, setDate] = useState<Value>(value);
//   // const [time, setTime] = useState<Value>(value);
//
//   useEffect(() => {
//     if (date && time) {
//       const combinedDateTime = new Date(
//           date.getFullYear(),
//           date.getMonth(),
//           date.getDate(),
//           time.getHours(),
//           time.getMinutes()
//       );
//       if (isValidDate(combinedDateTime)) {
//         onChange(combinedDateTime);
//       } else {
//         onChange(null);
//       }
//     } else {
//       onChange(null);
//     }
//   }, [date, time]);
//   // useEffect(() => {
//   //   if (date && time) {
//   //     const formattedHours = new Date(time).getHours();
//   //     const formattedMinutes = new Date(time).getMinutes();
//   //     const formattedDate = new Date(date);
//   //     formattedDate.setHours(formattedHours);
//   //     formattedDate.setMinutes(formattedMinutes);
//   //     if (isValidDate(formattedDate)) {
//   //       onChange(formattedDate)
//   //     } else {
//   //       onChange(null)
//   //     }
//   //   } else {
//   //     onChange(null)
//   //   }
//   // }, [date, time]);
//
//   const dateError = error && !isValidDate(date);
//   const timeError = error && !isValidDate(time);
//
//   const dateErrorTextToShow = dateError && isValidDate(date) ? dateErrorText : `${dateLabel} is invalid`
//   const timeErrorTextToShow = timeError && isValidDate(time) ? timeErrorText : `${timeLabel} is invalid`
//
//   return (
//     <Box
//       sx={{
//         display: 'inline-flex',
//         flexDirection: 'column',
//         minWidth: 0,
//         width: 320
//       }}
//     >
//       <Box>
//         {dateLabel && <InputLabel>{dateLabel}</InputLabel>}
//         <DatePicker
//           value={date}
//           onChange={(d: Value) => setDate(d)}
//           disabled={disabled}
//           sx={{
//             width: "100%",
//             ...pickersMigrationHack(error)
//           }}
//         />
//         {dateError ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit'}}>{dateErrorTextToShow}</FormHelperText> : <></>}
//       </Box>
//       <Box mt={2}>
//         {timeLabel && <InputLabel>{timeLabel}</InputLabel>}
//         <TimePicker
//           value={time}
//           onChange={(t: Value) => setTime(t)}
//           disabled={disabled}
//           sx={pickersMigrationHack(timeError)}
//         />
//         {timeError ? <FormHelperText sx={{ color: error ? '#d32f2f' : 'inherit'}}>{timeErrorTextToShow}</FormHelperText> : <></>}
//       </Box>
//     </Box>
//   );
// }
import { useState, useEffect } from "react";
import { Box, InputLabel, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isValidDate, pickersMigrationHack } from '../utils/utils';

type Value = Date | null;

interface MDateTimePickerProps {
  dateLabel?: string;
  timeLabel?: string;
  value?: Value;
  onChange: (date: Value) => void;
  disabled?: boolean;
  error?: boolean;
  dateErrorText?: string;
  timeErrorText?: string;
}

export const MDateAndTimePicker = (props: MDateTimePickerProps) => {
  const {
    dateLabel,
    timeLabel,
    value = null,
    onChange,
    disabled,
    error,
    dateErrorText,
    timeErrorText
  } = props;

  const extractDate = (dateTime: Value) => (dateTime ? new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()) : null);
  const extractTime = (dateTime: Value) => (dateTime ? new Date(1970, 0, 1, dateTime.getHours(), dateTime.getMinutes()) : null);

  // Combined state for both date and time
  const [combinedDateTime, setCombinedDateTime] = useState<Value>(value);

  useEffect(() => {
    if (value !== combinedDateTime) {
      setCombinedDateTime(value); // Update local state if external value changes
    }
  }, [value]);

  const handleDateChange = (date: Value) => {
    if (date) {
      const newDateTime = combinedDateTime
          ? new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              combinedDateTime.getHours(),
              combinedDateTime.getMinutes()
          )
          : new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setCombinedDateTime(newDateTime);
      onChange(isValidDate(newDateTime) ? newDateTime : null);
    } else {
      setCombinedDateTime(null);
      onChange(null);
    }
  };

  const handleTimeChange = (time: Value) => {
    if (time) {
      const newDateTime = combinedDateTime
          ? new Date(
              combinedDateTime.getFullYear(),
              combinedDateTime.getMonth(),
              combinedDateTime.getDate(),
              time.getHours(),
              time.getMinutes()
          )
          : new Date(1970, 0, 1, time.getHours(), time.getMinutes());
      setCombinedDateTime(newDateTime);
      onChange(isValidDate(newDateTime) ? newDateTime : null);
    } else {
      setCombinedDateTime(null);
      onChange(null);
    }
  };

  const dateError = error && (combinedDateTime ? !isValidDate(extractDate(combinedDateTime)) : true);
  const timeError = error && (combinedDateTime ? !isValidDate(extractTime(combinedDateTime)) : true);

  const dateErrorTextToShow = dateError ? dateErrorText || `${dateLabel} is invalid` : '';
  const timeErrorTextToShow = timeError ? timeErrorText || `${timeLabel} is invalid` : '';

  return (
      <Box
          sx={{
            display: 'inline-flex',
            flexDirection: 'column',
            minWidth: 0,
            width: 320
          }}
      >
        <Box>
          {dateLabel && <InputLabel>{dateLabel}</InputLabel>}
          <DatePicker
              value={extractDate(combinedDateTime)}
              onChange={handleDateChange}
              disabled={disabled}
              sx={{
                width: "100%",
                ...pickersMigrationHack(error)
              }}
          />
          {dateError && <FormHelperText sx={{ color: '#d32f2f' }}>{dateErrorTextToShow}</FormHelperText>}
        </Box>
        <Box mt={2}>
          {timeLabel && <InputLabel>{timeLabel}</InputLabel>}
          <TimePicker
              value={extractTime(combinedDateTime)}
              onChange={handleTimeChange}
              disabled={disabled}
              sx={pickersMigrationHack(timeError)}
          />
          {timeError && <FormHelperText sx={{ color: '#d32f2f' }}>{timeErrorTextToShow}</FormHelperText>}
        </Box>
      </Box>
  );
};