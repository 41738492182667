const DMSConfiguration = {
    dataBits : 8,
    baudRate: 115200,
    stopBits: 1
}

const sleep = (milliseconds: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
};

export const getPorts = async() => {
    try {
        // Get the list of available serial ports
        const ports = await navigator.usb.getDevices();

        // Assuming the serial ports provide manufacturer info, you can filter based on that
        const filteredPorts = ports.filter(port => {
            return port.vendorId === 0x0483 && port.productId === 0x5740;
        });
        console.log(ports);
        console.log(filteredPorts)
        return filteredPorts;
    } catch (error) {
        console.error("Error accessing serial ports:", error);
    }
}

export const requestPort = async() => {
    try {
        let device = await navigator.usb.requestDevice({
            filters: [
                { vendorId: 0x0483, productId: 0x5740 }
            ]
        });
        return [device, null]
    } catch (error) {
        console.error("Error requesting serial port:", error);
        return [null, (error as Error).message];
    }
};


export async function openDevice(device: USBDevice) {
    try {
        await device.open();
        if (device.configuration === null) {
            await device.selectConfiguration(1); // Select the first configuration
        }
        await device.claimInterface(1); // Claim interface 0
        console.log("Device opened and interface claimed.");
    } catch (error) {
        console.error("Failed to open device:", error);
    }
}

export async function closeDevice(device: USBDevice) {
    try {
        if (device.opened) {
            await device.releaseInterface(1); // Release the claimed interface
            await device.close(); // Close the device
            console.log("Device interface released and closed.");
        } else {
            console.log("Device is not open.");
        }
    } catch (error) {
        console.error("Failed to close device:", error);
    }
}

export async function logDeviceConfiguration(device: USBDevice) {
    await device.open();
    console.log("Device opened");
    if (device.configuration === null) {
        await device.selectConfiguration(1);
    }
    console.log("Configuration: ", device.configuration);

    if (device.configuration) {
        for (const iface of device.configuration.interfaces) {
            console.log("Interface: ", iface.interfaceNumber);
            for (const alt of iface.alternates) {
                console.log("Alternate Setting: ", alt.alternateSetting);
                for (const endpoint of alt.endpoints) {
                    console.log(`Endpoint: ${endpoint.endpointNumber}, Direction: ${endpoint.direction}, Type: ${endpoint.type}`);
                }
            }
        }
    }
    await device.close();
    console.log("Device closed");
}


export const _sendCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {

    let _ = await portAccess.transferOut(2, command);
    //console.log(command,_);
    if (with_escape_char) {
        const encoder = new TextEncoder();
        const commandEncoded = encoder.encode("\"\\r\\n\"");
        let _ = await portAccess.transferOut(2, commandEncoded);
        //console.log("\"\\r\\n\"",_);
    }
}

export const sendTextCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {
    const encoder = new TextEncoder();
    let commandEncoded = encoder.encode(command);
    await _sendCommand(portAccess,commandEncoded,with_escape_char);
}

export const sendBinaryCommand = async(portAccess:USBDevice,command:any,with_escape_char: boolean) => {
    await _sendCommand(portAccess,command,with_escape_char);
}

export const sendRequestSNCommand = async(portAccess:USBDevice) => {
    let command = new Uint8Array([192, 1, 192]);
    let with_escape_char = false;
    await _sendCommand(portAccess,command,with_escape_char);
}

export const sendRequestTimeCommand = async(portAccess:USBDevice) => {
    let command = new Uint8Array([192, 3, 192]);
    let with_escape_char = false;
    await _sendCommand(portAccess,command,with_escape_char);
}

export const readData = async(device: USBDevice, length: number) => {
    console.log("started readData");
    try {
        let result = await device.transferIn(2, length); // Use the IN endpoint number 2
        console.log(`Data received`);
        console.log(result.data)
        return result.data;
    } catch (error) {
        console.error("Failed to read data:", (error as Error).message);
        return null;
    }
}


// export const writeFrequency = async(device: USBDevice, frequency: number) => {
//     let frontendCommand = "";
//     let storageCommand = "";
//
//     if(frequency == 128){
//         frontendCommand = "set ECGFrontendFreq 1";
//         storageCommand = "set ECGStorageFreq 0";
//     }
//
//     if(frequency == 256){
//         frontendCommand = "set ECGFrontendFreq 1";
//         storageCommand = "set ECGStorageFreq 1";
//     }
//
//     if(frequency == 512){
//         frontendCommand = "set ECGFrontendFreq 2";
//         storageCommand = "set ECGStorageFreq 2";
//     }
//
//     if(frequency == 1024){
//         frontendCommand = "set ECGFrontendFreq 3";
//         storageCommand = "set ECGStorageFreq 3";
//     }
//
//     await sendTextCommand(device, frontendCommand, true);
//     await sendTextCommand(device, storageCommand, true);
//     await new Promise(resolve => setTimeout(resolve, 1000));
// }

// export const writeDuration = async(device: USBDevice, durationDays: number) => {
//     let durationMinutes = 1440 * durationDays;
//     let durationCommand = "set MeasureDuration "+durationMinutes.toString();
//
//     _sendCommand(device,durationCommand,true);
//     await new Promise(resolve => setTimeout(resolve, 1000));
//
//     _sendCommand(device,"save",true);
//     await new Promise(resolve => setTimeout(resolve, 5000));
// }

// export const writeTime = async(device: USBDevice) => {
//     const setTimeCommand = "settime " + new Date().toISOString();
//     _sendCommand(device,setTimeCommand,true);
//     await new Promise(resolve => setTimeout(resolve, 1000));
// }

// export const writeElectrodes = async(device: USBDevice, electrodes: any) => {
//     for (let i = 1; i <= 3; i++) {
//         const isEnabled = electrodes.includes(i.toString());
//         const value = isEnabled ? 10 + i * 2 - 1 : 0;
//         const command = `set ECGElectrodeConfigCh${i} ${value}`;
//         _sendCommand(device, command, true);
//         await new Promise(resolve => setTimeout(resolve, 1000));
//     }
// }

