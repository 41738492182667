import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import StudyForm from "../containers/StudyForm";
import { createStudy, createDraftStudy } from '../api/study';

const Studies = () => {
  const createStudyHandler = (values: any) => {
      return createStudy(values);
  }

  const draftStudyHandler = (values: any) => {
    createDraftStudy(values)
  }

  return (
    <>
      <PageHeader 
        title="New Study"
      />
      <Paper sx={{ marginBottom: 8}}>
        <StudyForm
          draftStudyHandler={draftStudyHandler}
          createStudyHandler={createStudyHandler} 
        />
      </Paper>
    </>
  );
};

export default Studies;