export const ELECTRODES = [
    {
        "label" : "Standard",
        "value" : "standard"
    },
    {
        "label" : "T Electrode",
        "value" : "t"
    }
]

export const CHANNELS = [
    {
        "label" : "Channel 1",
        "value" : "1"
    },
    {
        "label" : "Channel 2",
        "value" : "2"
    },
    {
        "label" : "Channel 3",
        "value" : "3"
    }
]


export const getDurations = (channelsList:any) => {
    const durations: any[] = [];
    for (let i = 14; i > 0; i--) {
        if (!(channelsList.length === 3 && i > 11)) {
            durations.push({
                    "label":(i).toString()+" Days",
                    "value":i
                }
            );
        }
    }
    return durations;
}

export const getFrequencies = (durationValue:any, channelsList: any) => {
    const frequenciesList: any[] = [];
    if(durationValue <= 3){
        frequenciesList.push({
            "label":(1024).toString()+" Hz",
            "value":1024}
        );
    }

    if(durationValue <= 5) {
        frequenciesList.push({
            "label":(512).toString()+" Hz",
            "value":512}
        );
    }

    if(durationValue <= 9) {
        frequenciesList.push({
            "label":(256).toString()+" Hz",
            "value":256}
        );
    }

    if(durationValue <= 11) {
        frequenciesList.push({
                "label": (128).toString() + " Hz",
                "value": 128
            }
        );
    } else if(durationValue <= 14 && channelsList.length < 3) {
        frequenciesList.push({
            "label":(128).toString()+" Hz",
            "value":128}
        );
    }

    return frequenciesList;
}